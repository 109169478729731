import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Header from "../common/header"
import ResponsiveImage from "../common/responsiveImage"
import ScrollDownAnimation from "../common/scrollDownAnimation"
import WelcomeImageLandscape from "../images/babylanguage/welcomeImageLandscape"
import WelcomeImagePortrait from "../images/babylanguage/welcomeImagePortrait"
import styles from "./welcome.module.css"

export default function WelcomeBabyLanguage(props) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(babylanguage/welcome.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              actionButton
              infoButton
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div>
      <ResponsiveImage
        portrait={<WelcomeImagePortrait className={styles.image} />}
        landscape={<WelcomeImageLandscape className={styles.image} />}
      />
      <div className={styles.wrapper}>
        <Header menuActive={props.menuActive} />
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.allMarkdownRemark.edges[0].node.html,
            }}
          />
        </div>
      </div>
      <ScrollDownAnimation href={`#start`}>
        {data.allMarkdownRemark.edges[0].node.frontmatter.infoButton}
      </ScrollDownAnimation>
    </div>
  )
}
