import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ImgWithText from "../common/imgWithText"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageBadge from "../common/responsiveImageBadge"
import ResponsiveImageText from "../common/responsiveImageText"
import BabyTongue from "../images/babyTongue"
import styles from "./intro.module.css"
import config from "../../../data/SiteConfig"
import BabyMumSquare from "../images/babylanguage/babyMumSquare"
import BabyHug from "../images/babylanguage/babyHug"
import Certificate from "../images/babylanguage/certificate"

export default function Intro() {
  const data = useStaticQuery(graphql`
    query {
      contentIntro: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(babylanguage/1_intro.md)/" } }
      ) {
        edges {
          node {
            frontmatter {
              actionButton
            }
            html
          }
        }
      }
      contentWhatIsIt: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(babylanguage/2_whatIsIt.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentHugText: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(babylanguage/3_hugText.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentFindYourRoutine: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(babylanguage/4_findYourRoutine.md)/" }
        }
      ) {
        edges {
          node {
            html
          }
        }
      }
      contentStatistics: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(babylanguage/5_statistics.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div id={`start`} className={styles.content}>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<div />}
            landscape={<BabyMumSquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentIntro.edges[0].node.html,
          }}
        />
      </ResponsiveImageText>
      <div
        dangerouslySetInnerHTML={{
          __html: data.contentWhatIsIt.edges[0].node.html,
        }}
      />
      <div className={styles.imageWrapper}>
        <ResponsiveImageBadge>
          <ImgWithText
            content={data.contentHugText.edges[0].node.html}
            contentClass={styles.hugContent}
          >
            <BabyHug />
          </ImgWithText>
        </ResponsiveImageBadge>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: data.contentFindYourRoutine.edges[0].node.html,
        }}
      />
      <div className={styles.weCanHelp}>
        <div
          className={styles.statistics}
          dangerouslySetInnerHTML={{
            __html: data.contentStatistics.edges[0].node.html,
          }}
        />
      </div>
      <div className={styles.imageWrapper}>
        <ResponsiveImageBadge>
          <ImgWithText
            content={``}
            contentClass={styles.tongueContent}
          >
            <Certificate />
          </ImgWithText>
        </ResponsiveImageBadge>
      </div>
    </div>
  )
}
