import React from "react"
import ContactUs from "../components/babylanguage/contactUs"
import Layout from "../components/babylanguage/layout"
import SEO from "../components/common/seo"
import Intro from "../components/babylanguage/intro"
import config from "../../data/SiteConfig"

const title = `babyschlafexpertin`

const BabyLanguagePage = () => (
  <Layout title={title} useWelcomeHeader={true} isBabyLanguage={true}>
    <SEO title={title} pathname="/">
      <script type={`application/ld+json`}>{`
        {
          "@context": "https://schema.org/",
          "@type": "Service",
          "name": "${config.organizationBabyLanguage} ${config.author}",
          "alternateName": "${config.organizationBabyLanguage}",
          "audience": {
            "@type": "Audience",
            "audienceType": "parents"
          },
          "category": "Babysprache",
          "provider": {
            "@type": "Person",
            "givenName": "${config.authorFirstName}",
            "additionalName": "${config.authorAltName}",
            "familyName": "${config.authorLastName}",
            "affiliation": {
              "@type": "Organization",
              "name": "${config.organizationBabyLanguage} ${config.author}"
            },
            "email": "${config.emailBabyLanguage}"
          },
          "serviceType": "ParentalSupport",
          "description": "${config.descriptionBabyLanguage}"
        }
      `}</script>
    </SEO>
    <Intro />
    <ContactUs  />
  </Layout>
)

export default BabyLanguagePage