import React from "react"
import styles from "./responsiveImageBadge.module.css"

export default function ResponsiveImageBadge(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>{props.children}</div>
    </div>
  )
}
