import React from "react"
import styles from "./imgWithText.module.css"

export default function ImgWithText(props) {
  return (
    <div className={styles.wrapper}>
      {props.children}
      <div
        className={`${styles.contentOverlay} ${props.contentClass}`}
        dangerouslySetInnerHTML={{
          __html: props.content,
        }}
      />
    </div>
  )
}
